// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-experience-assessment-js": () => import("./../../../src/pages/customer-experience-assessment.js" /* webpackChunkName: "component---src-pages-customer-experience-assessment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thank-you-download-js": () => import("./../../../src/pages/thank-you-download.js" /* webpackChunkName: "component---src-pages-thank-you-download-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-assets-js": () => import("./../../../src/templates/assets.js" /* webpackChunkName: "component---src-templates-assets-js" */),
  "component---src-templates-expertise-js": () => import("./../../../src/templates/expertise.js" /* webpackChunkName: "component---src-templates-expertise-js" */),
  "component---src-templates-insight-js": () => import("./../../../src/templates/insight.js" /* webpackChunkName: "component---src-templates-insight-js" */),
  "component---src-templates-insights-categories-js": () => import("./../../../src/templates/insightsCategories.js" /* webpackChunkName: "component---src-templates-insights-categories-js" */),
  "component---src-templates-insights-js": () => import("./../../../src/templates/insights.js" /* webpackChunkName: "component---src-templates-insights-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-work-categories-js": () => import("./../../../src/templates/workCategories.js" /* webpackChunkName: "component---src-templates-work-categories-js" */),
  "component---src-templates-work-single-js": () => import("./../../../src/templates/workSingle.js" /* webpackChunkName: "component---src-templates-work-single-js" */)
}

